import * as Sentry from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { useEffect } from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router";
import { User } from "../types";

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        traceFetch: false,
      }),
    ],
    beforeBreadcrumb: excludeGraphQLFetch,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      'can\'t redefine non-configurable property "get"',
    ],

    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function captureException(error: unknown) {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.captureException(error);
  }
}

export function captureMessage(message: string) {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.captureMessage(message, "error");
  }
}

export function setUser({ id, email, firstName, lastName }: User) {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.setUser({ id, email, username: `${firstName} ${lastName}` });
  }
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
