import { Button, Flex, Heading, Text } from "@radix-ui/themes";
import { useRouteError } from "react-router";
import { captureException } from "../services/sentry";
import Link from "../components/Link";

export default function ErrorPage() {
  const error = useRouteError();

  captureException(error);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100vh"
      gap="2"
    >
      <Heading size="6">Something went wrong</Heading>
      <Flex direction="column" align="center" gap="6">
        <Text as="div">
          Our team has been notified. If the problem continues, please contact
          us.
        </Text>
        <Flex gap="3">
          <Button onClick={() => window.location.reload()}>Refresh</Button>
          <Button variant="soft" color="gray">
            <Link to="mailto:product@freqens.com">Report Issue</Link>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
