import {
  Link as RadixLink,
  LinkProps as RadixLinkProps,
} from "@radix-ui/themes";
import { forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router";

export type LinkProps = RadixLinkProps & RouterLinkProps;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { children, ...props }: LinkProps,
  ref
) {
  return (
    <RadixLink asChild {...props}>
      <RouterLink ref={ref} {...props}>
        {children}
      </RouterLink>
    </RadixLink>
  );
});

export default Link;
