import { Box, Flex } from "@radix-ui/themes";
import { User } from "../../types";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router";
import SidebarButton from "../Sidebar/SidebarButton";
import { ArrowsClockwise, Files, Storefront } from "@phosphor-icons/react";
import React, { useLayoutEffect, useRef } from "react";

type AppLayoutProps = {
  user: User | null;
  logout: () => void;
  children: React.ReactNode;
};

export default function AppLayout({ user, logout, children }: AppLayoutProps) {
  const location = useLocation();
  const scrollArea = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    scrollArea.current?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Flex>
      <Sidebar user={user} logout={logout}>
        <SidebarButton
          to="subscriptions"
          active={location.pathname.startsWith("/subscriptions")}
          icon={ArrowsClockwise}
        >
          Subscriptions
        </SidebarButton>
        <SidebarButton
          to="vendors"
          active={location.pathname.startsWith("/vendors")}
          icon={Storefront}
        >
          Vendors
        </SidebarButton>
        <SidebarButton
          to="documents"
          active={location.pathname.startsWith("/documents")}
          icon={Files}
          soon
        >
          Documents
        </SidebarButton>
      </Sidebar>
      <Box overflow="scroll" height="100vh" width="100%" ref={scrollArea}>
        <Box px="6" pt="5" pb="8" mx="auto" maxWidth="1280px">
          {children}
        </Box>
      </Box>
    </Flex>
  );
}
